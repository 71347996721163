import Login from 'templates/Login';
import { getLayout } from 'layouts/Default';
import { BackgroundColor } from 'utils/ColorUtils';
import { NextPageWithLayout } from './_app';

const LoginPage: NextPageWithLayout = () => (
  <div className="pt-16 flex flex-col flex-grow">
    <Login />
  </div>
);

LoginPage.backgroundColor = BackgroundColor.SupportiveGray;
LoginPage.getLayout = getLayout;

export default LoginPage;
