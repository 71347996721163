import { useRouter } from 'next/router';
import Link from 'next/link';
import { appRoutes } from 'config';
import { reportUserClickedOnEvent } from 'utils/analytics/segment';

type Props = {
  isTransparent?: boolean;
};

const VisitorHeader = ({ isTransparent = false }: Props): JSX.Element => {
  const { pathname } = useRouter();
  const isLogin = pathname === appRoutes.LOGIN;

  return (
    <nav
      className={isTransparent ? styles.transparent.root : styles.default.root}
    >
      <div className={styles.default.container}>
        {/* Left - Logo */}
        <div className={styles.default.left}>
          <Link href={appRoutes.HOME} className={styles.default.logoHref}>
            <img
              className={styles.default.logoImg}
              src={
                isTransparent ? '/logotype-white.svg' : '/logotype-light.svg'
              }
              alt="MindFeed"
            />
          </Link>
        </div>
        {/* Right */}
        {!isLogin ? (
          <div className="flex items-center">
            <Link
              href={appRoutes.LOGIN}
              onClick={() =>
                reportUserClickedOnEvent('Get Started', 'Homepage Header')
              }
            >
              <button className="rounded-full text-mf-off-black-950 border border-mf-gray-300 bg-transparent py-2 px-4 mr-4">
                Log in
              </button>
            </Link>
            <Link
              href={appRoutes.LOGIN}
              onClick={() =>
                reportUserClickedOnEvent('Get Started', 'Homepage Header')
              }
            >
              <button className="rounded-full text-white bg-mf-blue-base py-2 px-4">
                Start now
              </button>
            </Link>
          </div>
        ) : null}
      </div>
    </nav>
  );
};

export default VisitorHeader;

const styles = {
  default: {
    root: 'fixed bg-white top-0 w-full z-20 border-b border-gray-200 shadow-sm',
    container: 'max-w-7xl mx-auto flex justify-between h-16 p-5 z-50',
    left: 'flex',
    logoHref: 'flex items-center',
    logoImg: 'block h-8 w-auto',
    right: 'flex items-center',
    ctaHref:
      'block w-full py-2 px-5 text-center bg-white border border-mf-blue-base rounded-md text-base font-medium text-mf-blue-base hover:bg-mf-blue-base hover:text-white sm:inline-block sm:w-auto transition duration-200 ease-in-out',
  },
  transparent: {
    root: 'fixed bg-transparent top-0 w-full z-20 border-b border-transparent',
    ctaHref:
      'block w-full py-2 px-5 text-center bg-transparent border border-white rounded-md text-base font-medium text-white hover:bg-mf-blue-base hover:text-white sm:inline-block sm:w-auto transition duration-200 ease-in-out',
  },
};
