import { appRoutes } from 'config';
import Link from 'next/link';
import type { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const copyright = `Copyright ©2025 by MindFeed Pty Ltd. All rights reserved.`;

const Footer = (): ReactElement => (
  <footer className={styles.outer}>
    <div className={styles.inner.container}>
      <div className={styles.inner.left.container}>
        <img
          className={styles.inner.left.logo}
          src="/images/mindfeed-logo-white.svg"
          alt="MindFeed logo in white on MindFeed website"
        />
        <p className={styles.inner.left.copyright}>{copyright}</p>
      </div>
      <div className={styles.inner.right.container}>
        <Link
          href={appRoutes.PRIVACY_POLICY}
          className={styles.inner.right.link}
        >
          Privacy Policy
        </Link>
        <Link href={appRoutes.TERMS_OF_USE} className={styles.inner.right.link}>
          Terms Of Use
        </Link>
        <Link href={appRoutes.HELP_CENTRE} className={styles.inner.right.link}>
          Help Centre
        </Link>
        <Link
          href={appRoutes.HELP_CENTRE}
          className={styles.inner.right.link}
        ></Link>
        <Link
          href="https://www.linkedin.com/company/mindfeed"
          className={styles.inner.right.link}
          target="_blank"
        >
          <FontAwesomeIcon icon={faLinkedinIn as IconProp} />
        </Link>
      </div>
      <p className={styles.inner.copyright}>{copyright}</p>
    </div>
  </footer>
);

export default Footer;

const styles = {
  outer: 'bg-mf-off-black-950 px-6 font-inter',
  inner: {
    container:
      'max-w-7xl mx-auto flex flex-col lg:flex-row justify-between py-4',
    left: {
      container:
        'text-white flex flex-row lg:gap-6 lg:items-center lg:justify-center',
      logo: 'py-4 lg:py-0',
      copyright: 'text-white hidden lg:block font-normal',
    },
    right: {
      container:
        'text-white flex flex-col lg:flex-row gap-4 lg:items-center lg:justify-center',
      link: 'text-white text-md font-light',
    },
    copyright: 'text-white lg:hidden font-normal pt-2',
  },
};
